import { motion } from "framer-motion";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { FC, MouseEventHandler, ReactNode, useCallback, useState } from "react";

import { classNames } from "../utils/classNames";
import { windowIfAvailable } from "../utils/windowIfAvailable";

export const UnderlineLink: FC<
  LinkProps & {
    active?: boolean;
    children: ReactNode;
    className?: string;
    borderClassName?: string;
    linkClassName?: string;
    external?: boolean;
  }
> = ({
  active,
  className,
  linkClassName,
  borderClassName,
  children,
  external,
  onClick,
  href,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  const router = useRouter();
  const openLink: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (e.metaKey) return;
      setClicked(true);
      onClick?.(e);
      e.preventDefault();
      if (external && typeof href === "string" && windowIfAvailable) {
        windowIfAvailable.location.href = href;
      } else {
        router.push(href);
      }
    },
    [external, href, onClick, router]
  );
  return (
    <motion.div
      className={classNames("flex-col inline-flex", className)}
      onHoverStart={() => {
        setHover(true);
      }}
      onHoverEnd={() => {
        setHover(false);
      }}
    >
      <Link
        {...props}
        className={classNames("px-6 font-medium no-underline", linkClassName)}
        onClick={openLink}
        href={href}
      >
        {children}
        <motion.div
          className={classNames(
            "border border-b border-x-0 border-frecBlack dark:border-frecBeige mx-auto",
            active ? "" : "w-0",
            borderClassName
          )}
          animate={{ width: hover || active || clicked ? "100%" : "0%" }}
        />
      </Link>
    </motion.div>
  );
};
